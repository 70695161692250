//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'error',
  computed: {
    message: function message() {
      return '页面不存在...';
    } } };